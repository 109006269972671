* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden !important;
}
.ql-toolbar.ql-snow {
  background: #f5f5f5;
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 100;
  border: none !important;
  padding: 2rem;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.315);
  flex-wrap: wrap;
  gap: 4;
}
.ql-toolbar {
  padding: 1rem !important;
}
.ql-container.ql-snow {
  width: 80%;
  margin: 10px auto 10px auto !important;
  height: 100vh !important;
}

.quill {
  background: #f5f5f5;
}
.ql-editor {
  min-height: 100vh !important;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.135);
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.142);
  font-size: 1.5rem;
}
.rotating-icon {
  animation: rotate 2s linear infinite;
  transform-origin: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
